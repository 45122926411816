/* eslint-disable import/no-unresolved */
import dynamic from 'next/dynamic';
import { isBrowser } from '@dx-ui/utilities-is-browser';
import { useWrappedRouter } from '../../hooks';

const TransactionComponent = dynamic(
  () => import('../../components/view-transactions/view-transactions'),
  {
    ssr: false,
  }
);

export function TransactionViewWrapper() {
  const { safeQueryParams: queryParameters } = useWrappedRouter();
  if (!isBrowser || !queryParameters?.viewTxns) {
    return null;
  }
  return <TransactionComponent />;
}
