import { isBrowser } from '@dx-ui/utilities-is-browser';
import type { FieldValues } from 'react-hook-form';

/**
 * Utility to provide proper offset for mobile browsers to prevent browser navbar from interfering
 * @returns string
 */
export const setBottomPadding = () => {
  if (isBrowser) {
    if (window.navigator.userAgent.match(/iPhone/i)) return '4.0rem';
    if (window.navigator.userAgent.match(/Android/i)) return '3.5rem';
  }
  return '0.25rem';
};

/**
 * Parses data (or defaultFilters) and returns count of active filters. Assumes length of an array as active filter. Booleans are counted if filter returns true.
 * @param data
 * @returns Number
 */
export const getActiveFilterCount = (data: FieldValues) => {
  return Object.values(data ?? {}).reduce((sum, currFilterGroup) => {
    // Case 1. Is an array of filters. Just count total filters
    if (Array.isArray(currFilterGroup)) return sum + (currFilterGroup as unknown[]).length;
    // Case 2. Is a boolean filter. Just add 1 if it's true
    if (currFilterGroup) return sum + 1;
    return sum;
  }, 0);
};
