import type { FormDataStructure } from '@dx-ui/osc-form';
import { ActionDialog } from '@dx-ui/osc-dialog';
import { useTranslation } from 'next-i18next';
import * as React from 'react';
import { useForm, type FieldValues, FormProvider } from 'react-hook-form';
import cx from 'classnames';
import type { DefaultFilters } from './filters';
import cloneDeep from 'lodash/cloneDeep';

export type FiltersModal = {
  applyBtnAccessibleLabel?: string;
  applyBtnLabel?: string;
  children?: React.ReactNode;
  contentClassName?: string;
  defaultFilters?: DefaultFilters;
  title?: string;
  isModalOpen: boolean;
  onCloseModal?: () => void;
  onSubmit?: (data: FieldValues) => void;
  resetBtnLabel?: string;
};

const resetDefaults: DefaultFilters = {
  amenities: [],
  brands: [],
  saleFilter: false,
  showAvailableHotelsOnly: false,
  priceFilter: '',
  tripAdvisorRating: 'allRatings',
};
export const FiltersModal = ({
  applyBtnAccessibleLabel,
  applyBtnLabel,
  children,
  contentClassName,
  defaultFilters,
  onCloseModal,
  onSubmit,
  title,
  isModalOpen,
  resetBtnLabel,
}: FiltersModal) => {
  const { t } = useTranslation('osc-filters');
  const [initialValue, setInitialValue] = React.useState<DefaultFilters | undefined>();

  React.useEffect(() => {
    if (isModalOpen) setInitialValue(cloneDeep(defaultFilters));
    reset(defaultFilters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isModalOpen]);

  const methods = useForm<FormDataStructure>({
    defaultValues: defaultFilters,
    mode: 'onTouched',
  });
  const { getValues, reset } = methods;

  const resetFilters = () => {
    reset(resetDefaults);
  };
  const dismissFilters = () => {
    reset(initialValue, { keepDefaultValues: true });
    onCloseModal?.();
  };
  return (
    <FormProvider {...methods}>
      <ActionDialog
        ariaLabel={title ?? t('filters')}
        className="flex flex-col overflow-hidden"
        contentClassName={cx('overflow-hidden', contentClassName)}
        isOpen={isModalOpen}
        onConfirm={() => onSubmit?.(getValues())}
        onDismiss={dismissFilters}
        onReset={resetFilters}
        size="3xl"
        title={title ?? t('filters')}
        buttonOptions={{
          confirm: {
            label: applyBtnLabel ?? t('applyFiltersLabel'),
            adaDescription: applyBtnAccessibleLabel ?? t('andClose'),
          },
          reset: { isEnabled: true, label: resetBtnLabel ?? t('resetFiltersLabel') },
        }}
      >
        {/* composed filters will go inside this tag as children */}
        <main className="overflow-auto" role="main">
          <div className="divide-border-alt divide-y">{children}</div>
        </main>
      </ActionDialog>
    </FormProvider>
  );
};
