import type { FilterMaxPricePill } from './filter-price-pills';
import { FilterMaxPricePills } from './filter-price-pills';
import { useTranslation } from 'next-i18next';
import { MAX_PRICE_FILTER_INPUT_CHARS } from './utils/pricing-filter-constants';
import { FormInput } from '@dx-ui/osc-form';
import { useFormContext, useWatch } from 'react-hook-form';
import { PriceFilterSkeleton } from './price-filter-skeleton';
import * as React from 'react';
import cx from 'classnames';
import Icon from '@dx-ui/osc-icon';
import { FilterMaxPriceRadioButtons } from './filter-max-price-radio-buttons';

export type PricingFilterProps = {
  /**Currency symbol to label input field and description for accessibility */
  currency: { symbol: string; description: string; code: string };
  /**Disable price filter elements   */
  hidePriceFilter?: boolean;
  /**Price filter loading state, will active shimmer if true*/
  isLoading?: boolean;
  /**
   * Array of price consideration messages that will appear under the price filter header.<br/>
   * Suggested values from OSC filters resource bundle:<br/>
   * <code>osc-filters: perNightPriceFilterMessage - "You are filtering on a per night price"</code><br/>
   * <code>osc-filters: daytimeUsePriceMessage - "You are filtering a daytime use price."</code>
   */
  priceConsiderationMessage?: string[];
  /**Active price filter state converted price value to set input value on reopen<br/>
   * recommend value: <code>[filterstate].priceFilter.convertedPrice</code>
   */
  priceFilter?: number | string | FilterMaxPricePill;
  /**Object array for filter pill display and numerical values <br/>
   * <code>[{ basePrice: number; convertedPrice: number;}]</code>
   */
  priceListForPills: FilterMaxPricePill[];
  /**Sale filter active */
  isSaleFilter?: boolean;
  /**Toggle price filter in DOM */
  showPriceFilter?: boolean;
  /**Toggle sale filter checkbox */
  showSaleFilter?: boolean;
  /** NHCSEARCH-5048 MVT - replaces pills with radio buttons */
  showRadioButtons?: boolean;
  /** NHCSEARCH-5310 MVT - max points filter */
  isPointsFilter?: boolean;
};
export const PricingFilter = ({
  currency,
  hidePriceFilter = false,
  isLoading,
  priceConsiderationMessage,
  priceFilter,
  priceListForPills,
  isSaleFilter,
  showPriceFilter = true,
  showSaleFilter = true,
  showRadioButtons = false,
  isPointsFilter = false,
}: PricingFilterProps) => {
  const { t } = useTranslation('osc-filters');
  const { setValue } = useFormContext();
  const maxPillValue = priceListForPills?.[priceListForPills.length - 1]?.convertedPrice;

  const displayCurrencySymbol = currency?.symbol?.replace(/\{0\}/, '') || currency?.code || '$';

  const priceFieldValue = useWatch({ name: 'priceFilter' });
  const handlePricePillClick = (pill: FilterMaxPricePill) => {
    setValue('priceFilter', pill.convertedPrice ?? '');
  };

  //limit characters in numeric input
  const onPriceFieldInput = (e: React.FormEvent<HTMLInputElement>) => {
    //remove any non numerical characters from input value
    const newValue = (e.target as HTMLInputElement).value.replace(/[^\d]/g, '');
    //if the numerical value exceed the defined max, slice value to respect max length
    if (newValue.length > MAX_PRICE_FILTER_INPUT_CHARS) {
      setValue('priceFilter', newValue.slice(0, MAX_PRICE_FILTER_INPUT_CHARS));
    } else {
      //if numerical value within limitation of max length, set value
      setValue('priceFilter', newValue);
    }
  };

  React.useEffect(() => {
    const effectivePriceFilter = showRadioButtons
      ? String(priceFilter || 'noMaximum')
      : priceFilter;
    if (effectivePriceFilter) setValue('priceFilter', effectivePriceFilter);
    if (showSaleFilter && isSaleFilter) setValue('saleFilter', true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showRadioButtons]);

  if (!showPriceFilter) return null;
  if (isLoading) return <PriceFilterSkeleton />;
  return (
    <div className="my-2  px-4">
      <h3 className="label label-inline-reverse mt-3 text-base font-semibold">
        {isPointsFilter ? t('maxPointsPerNight') : t('maxPriceRangeLabel')}
      </h3>
      <div className="ml-2 flex w-auto flex-col items-start">
        {!!priceConsiderationMessage &&
          priceConsiderationMessage.map((message) => (
            <div className="text-text-alt text-sm" key={message}>
              {message}
            </div>
          ))}

        {showRadioButtons ? (
          <FilterMaxPriceRadioButtons
            currencyCode={currency.code}
            isPointsFilter={isPointsFilter}
            hidePriceRadioButtons={hidePriceFilter}
            priceList={priceListForPills}
          />
        ) : (
          <>
            <FilterMaxPricePills
              currencyCode={currency.code}
              hidePricePills={hidePriceFilter}
              handlePricePillClick={handlePricePillClick}
              maxValue={priceFieldValue}
              pillPriceList={priceListForPills}
            />
            <FormInput
              containerClassName={cx('border-border-alt p-0 m-0 border-none w-full h-full pb-2', {
                'text-text-alt bg-bg-disabled  pb-0': hidePriceFilter,
              })}
              className={cx(
                'mt-0 mb-1 inline w-full appearance-none self-center border-none pl-1 leading-3 rtl:pr-1',
                {
                  'm-0 p-0': hidePriceFilter,
                }
              )}
              data-testid="priceFilter-max"
              disabled={hidePriceFilter}
              labelClassName="border-border-alt font-normal flex m-0 flex-row items-center w-3/4 border p-0"
              id="maxPrice"
              placeholder={`${maxPillValue?.toLocaleString()}+`}
              type="text"
              onInput={(e) => onPriceFieldInput(e)}
              label={
                <>
                  <span aria-hidden className="h-full w-auto px-2">
                    {displayCurrencySymbol}
                  </span>
                  <span className="sr-only">{currency?.description || ''}</span>
                </>
              }
              name="priceFilter"
            />
          </>
        )}

        {showSaleFilter ? (
          <div className="py-1">
            <FormInput
              type="checkbox"
              containerClassName="flex items-center pt-2"
              label={
                <div className="flex items-center">
                  <span className="mr-2 flex size-6 shrink-0 items-center">
                    <Icon name="sale" size="md" />
                  </span>
                  <span className="rtl:px-2">{t('showSaleRates')}</span>
                </div>
              }
              labelClassName="flex items-start mr-2.5"
              name="saleFilter"
            />
          </div>
        ) : null}
      </div>
    </div>
  );
};
