import { FormInput } from '@dx-ui/osc-form';
import { useTranslation } from 'next-i18next';

export type ShowAvailableHotelsOnlyFilterProps = {
  isDisabled?: boolean;
  isVisible?: boolean;
} & React.HTMLAttributes<HTMLInputElement>;

const ShowAvailableHotelsOnlyFilter = ({
  isDisabled = false,
  isVisible = true,
}: ShowAvailableHotelsOnlyFilterProps) => {
  const { t } = useTranslation('osc-filters');

  return isVisible ? (
    <div className="px-3 py-1.5">
      <FormInput
        disabled={isDisabled}
        id="showAvailableHotelsOnly"
        label={<span className="rtl:px-2">{t('showAvailableText')}</span>}
        labelClassName="flex"
        name="showAvailableHotelsOnly"
        type="checkbox"
      />
    </div>
  ) : null;
};

export { ShowAvailableHotelsOnlyFilter };
