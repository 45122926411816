import dynamic from 'next/dynamic';

export const Filters = dynamic(() => import('../../components/filters/filters'), {
  ssr: false,
});
export const HotelDetails = dynamic(() => import('../hotel-card/hotel-details/hotel-details'), {
  ssr: false,
});

//shop form
export const ShopForm = dynamic(() => import('@dx-ui/osc-shop-form').then((mod) => mod.ShopForm), {
  ssr: false,
});
