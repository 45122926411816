// BUG in nx: https://github.com/nrwl/nx/issues/15514
// eslint-disable-next-line @nx/enforce-module-boundaries
import { Speedbump, useSpeedbump } from '@dx-ui/osc-speedbump';
import { Footer as OSCFooter } from '@dx-ui/osc-footer';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { FOOTER_LINKS } from './footer-information';

const Footer = ({ isFluid }: { isFluid?: boolean }) => {
  const { t } = useTranslation('footer');
  const { locale } = useRouter();
  const { onShow, onHide, isVisible, onContinue } = useSpeedbump(locale as string);

  return (
    <>
      <Speedbump isShowing={isVisible} onClose={onHide} onContinue={onContinue} />
      <OSCFooter
        contactPhoneLink={{ url: 'tel:+1-800-446-6677', label: '+1-800-4honors' }}
        customerSupportLink={{
          url: 'https://help.hilton.com/s/',
          target: '_blank',
          rel: 'noopener noreferrer',
        }}
        isFluid={isFluid}
        links={FOOTER_LINKS(t, locale || 'en', onShow)}
      />
    </>
  );
};

export default Footer;
