import type { FieldValues } from 'react-hook-form';
import type { FilterMaxPricePill } from '../filter-price-pills';
import type { ConversionCurrencyOptions } from '@dx-ui/osc-currency-converter';
import { formatAndConvertPrice } from '@dx-ui/osc-currency-converter';

//Recommended to use in filters form onSubmit to transform pricing filter value to price object
export const transformPricingFilterData = ({
  data,
  isCurrenciesLoading,
  pillsPriceList,
  toCurrency,
  fromCurrency,
  language = 'en',
}: {
  data: FieldValues;
  isCurrenciesLoading: boolean;
  pillsPriceList: FilterMaxPricePill[];
  toCurrency?: ConversionCurrencyOptions;
  fromCurrency?: ConversionCurrencyOptions;
  language?: string;
}) => {
  //if no priceList filter data, return undefined
  if (!data?.priceFilter) return undefined;
  //if currencies not found, return undefined
  if (isCurrenciesLoading) return undefined;
  let convertedBasePrice = data?.priceFilter;
  // convert priceFieldValue, basePrice is always in USD
  if (fromCurrency?.currencyCode !== 'USD') {
    convertedBasePrice = formatAndConvertPrice({
      language,
      fromCurrency,
      toCurrency,
      basePrice: data?.priceFilter?.convertedPrice
        ? data?.priceFilter?.convertedPrice
        : Number.parseInt(data?.priceFilter, 10),
    });
    convertedBasePrice = convertedBasePrice?.replace(/\$/g, '');
  }
  const pillPriceMatch = pillsPriceList.find(
    (priceData) =>
      priceData?.basePrice === data?.priceFilter ||
      priceData?.basePrice === convertedBasePrice?.basePrice
  );
  //if converted price matches price pill values, return pill value object
  return pillPriceMatch
    ? pillPriceMatch
    : //if there is only an input value match, assign to converted price and undefined base
      {
        basePrice: convertedBasePrice || data.priceFilter || '',
        convertedPrice: data.priceFilter,
      };
};

export const getCurrencySymbol = (currency?: ConversionCurrencyOptions) =>
  currency?.currencySymbolFormat?.replace(/\{0\}/, '') || currency?.currencyCode || '$';

export const getFormattedPrice = (price?: number, currency?: ConversionCurrencyOptions) => {
  const formattedPrice = price?.toLocaleString();
  let displayPrice = `$${formattedPrice}`;
  if (currency?.currencySymbolFormat)
    displayPrice = currency?.currencySymbolFormat?.replace(/\{0\}/, `${formattedPrice}`);
  else if (currency?.currencyCode) displayPrice = `${formattedPrice} ${currency?.currencyCode}`;

  return displayPrice;
};
