import type { Facts } from '@dx-shared/rule-engine';

const BASE_LOCALE = 'en';

export const handleAllArray = (allArray: Facts[]): string | undefined => {
  const hasUrl = allArray?.some(
    (item) =>
      item.fact === 'url' &&
      Array.isArray(item.value) &&
      item.value.includes('*/locations/*') &&
      item.value.includes('*/search/*')
  );
  if (hasUrl) {
    const languageObj = allArray?.find((item) => item.fact === 'language');
    return languageObj?.value as string;
  }
  return undefined;
};
export type AnyType =
  | {
      any: {
        all: Facts[];
      }[];
    }
  | undefined
  | null;
export const getSearchLanguagesFromRuleUiTranslateConfig = (data: AnyType) => {
  const supportedLocales = (data?.any || [])
    ?.flatMap((condition) => {
      return handleAllArray(condition.all);
    })
    ?.filter((value) => value !== undefined);
  return [BASE_LOCALE, ...supportedLocales];
};
