import { useTranslation } from 'next-i18next';
import cx from 'classnames';
import { NO_MAX_PILL } from './utils/pricing-filter-constants';
import { useCurrencyConversion } from '@dx-ui/osc-currency-converter';
import { getCurrencySymbol, getFormattedPrice } from './utils/pricing-filter-utils';

export type FilterMaxPricePill = {
  basePrice?: number;
  convertedPrice?: number;
};

export type FilterPriceRangeProps = {
  currencyCode: string;
  hidePricePills?: boolean;
  pillPriceList: FilterMaxPricePill[];
  maxValue?: string;
  handlePricePillClick: (pill: FilterMaxPricePill) => void;
};

export const FilterMaxPricePills = ({
  currencyCode,
  hidePricePills = false,
  pillPriceList,
  maxValue,
  handlePricePillClick,
}: FilterPriceRangeProps) => {
  const { t } = useTranslation('osc-filters');
  const { currenciesMap } = useCurrencyConversion();
  const currencyDetails = currenciesMap.get(currencyCode);

  //copy price list and add no maximum pill
  const pillPrices = [...pillPriceList, NO_MAX_PILL];
  const getPillValue = (i: number, pill: FilterMaxPricePill, arr: FilterMaxPricePill[]) => {
    const formattedPrice = getFormattedPrice(pill.convertedPrice, currencyDetails);
    if (i === 0) return t('minPriceFilterPrice', { minPrice: formattedPrice });
    if (i === arr.length - 1) return t('noMaximum');
    return formattedPrice;
  };

  return (
    <ul className="flex w-auto flex-wrap justify-start gap-2 pb-3 pt-2 font-bold">
      {pillPrices.map((pill, i, arr) => {
        const isActive =
          !hidePricePills &&
          (pill.convertedPrice === Number(maxValue) ||
            (!maxValue && pill.convertedPrice === undefined));
        return (
          <li className="pt-1 font-bold" key={pill.convertedPrice || 'noMaxPill'}>
            <button
              disabled={hidePricePills}
              name={
                !pill.convertedPrice
                  ? t('noMaximum')
                  : t('ariaMaxPrice', {
                      price: `${getCurrencySymbol(currencyDetails)}${pill.convertedPrice}`,
                    })
              }
              className={cx(
                'btn bg-bg  focus:bg-primary focus:text-bg align-center inline-block rounded-2xl border-none py-1 px-2.5 font-normal ring-1  ring-primary focus:shadow-none',
                {
                  'text-bg bg-primary focus:outline-none': isActive,
                  'text-primary': !isActive,
                  'text-text-disabled ring-bg-disabled': hidePricePills,
                }
              )}
              onClick={() => handlePricePillClick(pill)}
              type="button"
            >
              {getPillValue(i, pill, arr)}
            </button>
          </li>
        );
      })}
    </ul>
  );
};
