//NHCSEARCH-5338 MVT Price Planner

import { PRICE_PLANNER } from '../../../../constants';
import { useGetMVTSelections, useWrappedRouter } from '../../../../hooks';
import { getPageType } from '../../../../utils';

export const useGetPricePlannerMVTVariants = () => {
  const { router } = useWrappedRouter();
  const { isDefaultLocations: defaultLocationsPage } = getPageType(router.asPath);
  const { isLoaded: isPricePlannerTestLoaded, isMVTVariant: PricePlannerVariant } =
    useGetMVTSelections({
      agentId: PRICE_PLANNER.agentId,
      MVTVariants: ['a', 'b', 'c'],
    });
  const isPricePlannerTestEnabled = defaultLocationsPage && isPricePlannerTestLoaded;
  const isPricePlannerBannerVariant = isPricePlannerTestEnabled && !!PricePlannerVariant?.b;
  const isPricePlannerCTAVariant = isPricePlannerTestEnabled && !!PricePlannerVariant?.c;

  return { isPricePlannerBannerVariant, isPricePlannerCTAVariant };
};
