import { InfoMessageBanner } from '../../list-view/info-message-banner/info-message-banner';
import { useState } from 'react';
import { PlanYourStayModal } from './plan-your-stay-modal';
import { useTranslation } from 'next-i18next';
import { useGetPricePlannerMVTVariants } from './hooks/use-price-planner-mvt';
import { GOALS } from '../../../constants';
import { sendReward } from '@dx-ui/framework-conductrics';

export const PlanToBookWithinBudgetBanner = () => {
  const { isPricePlannerBannerVariant } = useGetPricePlannerMVTVariants();
  const { t } = useTranslation(['default-locations-page']);
  const [showBookWithinBudgetModal, setShowBookWithinBudgetModal] = useState(false);

  const openBookWithinBudgetModal = () => {
    sendReward(GOALS.pricePlannerCTAClick);
    setShowBookWithinBudgetModal(true);
  };
  const closeBookWithinBudgetModal = () => {
    setShowBookWithinBudgetModal(false);
  };

  return isPricePlannerBannerVariant ? (
    <>
      <InfoMessageBanner
        bannerClassName="bg-info-alt justify-center mt-4"
        iconClassName="text-normal"
        iconName="wallet"
      >
        <div className="pl-2.5 pt-px font-semibold">
          <button
            onClick={openBookWithinBudgetModal}
            type="button"
            className="max-sm:flex max-sm:flex-col"
          >
            <span className="font-bold">{t('pricePlanner.planningToBookWithinBudget')}</span>
            <span className="pl-2 font-normal underline max-sm:pl-0">
              {t('pricePlanner.usePricePlannerTool')}
            </span>
          </button>
        </div>
      </InfoMessageBanner>
      <PlanYourStayModal
        isOpen={showBookWithinBudgetModal}
        onDismiss={closeBookWithinBudgetModal}
      />
    </>
  ) : null;
};
