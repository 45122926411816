import type { Link } from '@dx-ui/osc-link';
import { OHW_SEARCH_PRIMARYSLUGS } from '../../constants';
import type { TFunction } from 'i18next';
import {
  HGV_MEMBER_PROGRAM_LINKS,
  TMTP_ELIGIBLE_TEAM_MEMBER_PROGRAM_LINKS,
  TMTP_NON_TEAM_MEMBER_PROGRAM_LINKS,
} from './header-constants';

export function getAdditionalLinks(
  isTMTPTeamMember: boolean | undefined,
  isTPHMember: boolean | undefined,
  isTMTPEligibleMember: boolean | undefined,
  t: TFunction<'header'>,
  isHGVOwner: boolean | undefined
) {
  const additionalLinks: Link[] = [];
  if (isTMTPTeamMember || isTMTPEligibleMember || isTPHMember)
    additionalLinks.push(...TMTP_NON_TEAM_MEMBER_PROGRAM_LINKS(t));
  if (isHGVOwner) additionalLinks.push(...HGV_MEMBER_PROGRAM_LINKS(t));
  if (isTMTPTeamMember && !isTMTPEligibleMember)
    additionalLinks.push(...TMTP_ELIGIBLE_TEAM_MEMBER_PROGRAM_LINKS(t));
  return additionalLinks;
}

export const removePrimarySlug = (url: string) => {
  let modifiedUrl = url;
  OHW_SEARCH_PRIMARYSLUGS.forEach((primarySlug) => {
    modifiedUrl = modifiedUrl.replace(`${primarySlug}/`, '');
  });
  return modifiedUrl;
};
const AUTHENTICATED_ONLY_PARAMS = [
  'smbRate',
  'employeeRate',
  'ownerVIPRate',
  'friendsAndFamilyRate',
  'ownerHGVRate',
];
export const hasAuthenticatedParam = (url: string) => {
  let hasParam = false;
  AUTHENTICATED_ONLY_PARAMS.forEach((param) => {
    if (url.includes(`${param}=`)) hasParam = true;
  });
  return hasParam;
};

export const hasPrimarySlug = (url: string) => {
  let hasSlug = false;
  OHW_SEARCH_PRIMARYSLUGS.forEach((param) => {
    if (url.includes(`/${param}/`)) hasSlug = true;
  });
  return hasSlug;
};
export const sanitizedSearchUrlParams = (originalUrl: string) => {
  const url = new URL(originalUrl);
  AUTHENTICATED_ONLY_PARAMS.forEach((param) => {
    url.searchParams.delete(param);
  });
  return url;
};
