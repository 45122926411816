import type { Link } from '@dx-ui/osc-link';
import { env } from '../../constants';
import type { TFunction } from 'i18next';

export const HGV_MEMBER_PROGRAM_LINKS = (t: TFunction<'header'>): Link[] => [
  {
    url: env.EXTERNAL_HGV_PROGRAM_DETAILS_URL,
    label: t('programDetails', { for: 'HGV Max' }),
    isNewWindow: true,
  },
];

export const TMTP_NON_TEAM_MEMBER_PROGRAM_LINKS = (t: TFunction<'header'>): Link[] => [
  {
    url: env.EXTERNAL_TMTP_PROGRAM_DETAILS_URL,
    label: t('programDetails', { for: 'Go Hilton' }),
    isNewWindow: true,
  },
];

export const TMTP_ELIGIBLE_TEAM_MEMBER_PROGRAM_LINKS = (t: TFunction<'header'>): Link[] => [
  {
    url: env.TMTP_MANAGE_FAMILY_AND_FRIENDS_LINK,
    label: t('manageFF'),
    isNewWindow: true,
  },
];
