import cx from 'classnames';
export const PriceFilterSkeleton = () => (
  <div>
    <div aria-hidden className="mt-4" data-testid="priceRange-currency-loading">
      <div className="ml-4">
        <div className="bg-bg-alt mt-3 h-4 w-32 animate-pulse rounded-2xl" />
        <div className="bg-bg-alt mt-3 h-3.5 w-48 animate-pulse rounded-2xl" />

        <div className="mt-3">
          {Array.from({ length: 5 }, (_, i) => {
            const isEnd = i === 0 || i === 4;
            return (
              // eslint-disable-next-line no-restricted-globals
              <span key={`price-pill-${i}`}>
                <div
                  className={cx(
                    'bg-bg-alt mx-1 mt-3 inline-block h-8 w-14 animate-pulse rounded-2xl',
                    {
                      'w-28': isEnd,
                      'ml-0': i === 0,
                    }
                  )}
                />
              </span>
            );
          })}
        </div>
        <div className="bg-bg-alt mb-3 ml-3 mt-2 flex h-10 w-96 animate-pulse rounded-2xl" />
      </div>
    </div>
  </div>
);
