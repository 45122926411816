import { useTranslation } from 'next-i18next';
import { NO_MAX_PILL } from './utils/pricing-filter-constants';
import { useCurrencyConversion } from '@dx-ui/osc-currency-converter';
import { getFormattedPrice } from './utils/pricing-filter-utils';
import { FormInput } from '@dx-ui/osc-form';
import type { FilterMaxPricePill } from './filter-price-pills';
import { useRouter } from 'next/router';
import { DEFAULT_LANGUAGE } from '@dx-ui/utilities-dates';

export type FilterPriceRadioButtonsProps = {
  currencyCode: string;
  hidePriceRadioButtons?: boolean;
  isPointsFilter?: boolean;
  priceList: FilterMaxPricePill[];
};
const formatPoints = (points?: number, lang = DEFAULT_LANGUAGE) =>
  points ? new Intl.NumberFormat(lang).format(points) : '';

export const FilterMaxPriceRadioButtons = ({
  currencyCode,
  hidePriceRadioButtons = false,
  isPointsFilter = false,
  priceList,
}: FilterPriceRadioButtonsProps) => {
  const { t } = useTranslation('osc-filters');
  const { currenciesMap } = useCurrencyConversion();
  const { locale } = useRouter();
  const currencyDetails = currenciesMap.get(currencyCode);

  const prices = [...priceList, NO_MAX_PILL];

  return (
    <ul className="-ms-4 space-y-3 py-3">
      {prices.map((price, i) => {
        const isFinalPrice = i === prices.length - 1;
        const value = isFinalPrice ? 'noMaximum' : price.convertedPrice;
        const getLabelText = () => {
          let pointsPriceLabelText = '';
          if (isFinalPrice) return t('noMaximum');

          if (isPointsFilter)
            pointsPriceLabelText = formatPoints(price?.basePrice, locale || DEFAULT_LANGUAGE);
          else if (currencyDetails)
            pointsPriceLabelText = getFormattedPrice(price.convertedPrice, currencyDetails);

          if (i === 0 && isPointsFilter)
            return t('lessThanPriceOrPoints', { priceOrPoints: pointsPriceLabelText });

          return pointsPriceLabelText;
        };
        return (
          <li key={value}>
            <FormInput
              labelClassName="label label-inline-reverse"
              disabled={hidePriceRadioButtons}
              name="priceFilter"
              type="radio"
              label={getLabelText()}
              value={value}
            />
          </li>
        );
      })}
    </ul>
  );
};
