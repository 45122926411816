import type { TFunction } from 'i18next';

export type TripAdvisorRatings =
  | 'fiveAndUp'
  | 'fourAndUp'
  | 'threeAndUp'
  | 'twoAndUp'
  | 'allRatings';

type ImageLabelValue = {
  altOrLabel: string;
  value: TripAdvisorRatings;
  onlyRatingImgSrc: string;
  logoWithRatingImgSrc: string;
};

export const GET_TRIP_ADVISOR_RATINGS_INFO = (t: TFunction<'osc-filters'>): ImageLabelValue[] => [
  {
    altOrLabel: t('fiveRatingsAltText'),
    value: 'fiveAndUp',
    onlyRatingImgSrc: 'https://www.tripadvisor.com/img/cdsi/img2/ratings/traveler/s5.0-26025-5.svg',
    logoWithRatingImgSrc:
      'https://www.tripadvisor.com/img/cdsi/img2/ratings/traveler/5.0-26025-5.svg',
  },
  {
    altOrLabel: t('ratingAndUpAltText', { rating: 4 }),
    value: 'fourAndUp',
    onlyRatingImgSrc: 'https://www.tripadvisor.com/img/cdsi/img2/ratings/traveler/s4.0-26025-5.svg',
    logoWithRatingImgSrc:
      'https://www.tripadvisor.com/img/cdsi/img2/ratings/traveler/4.0-26025-5.svg',
  },
  {
    altOrLabel: t('ratingAndUpAltText', { rating: 3 }),
    value: 'threeAndUp',
    onlyRatingImgSrc: 'https://www.tripadvisor.com/img/cdsi/img2/ratings/traveler/s3.0-26025-5.svg',
    logoWithRatingImgSrc:
      'https://www.tripadvisor.com/img/cdsi/img2/ratings/traveler/3.0-26025-5.svg',
  },
  {
    altOrLabel: t('ratingAndUpAltText', { rating: 2 }),
    value: 'twoAndUp',
    onlyRatingImgSrc: 'https://www.tripadvisor.com/img/cdsi/img2/ratings/traveler/s2.0-26025-5.svg',
    logoWithRatingImgSrc:
      'https://www.tripadvisor.com/img/cdsi/img2/ratings/traveler/2.0-26025-5.svg',
  },
  {
    altOrLabel: t('allRatingsText'),
    value: 'allRatings',
    onlyRatingImgSrc: '',
    logoWithRatingImgSrc: '',
  },
];
