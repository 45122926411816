import { useTranslation } from 'next-i18next';
import { GET_TRIP_ADVISOR_RATINGS_INFO } from './trip-advisor-filters.constants';
import Image from 'next/legacy/image';
import { FormInput } from '@dx-ui/osc-form';

export const TripAdvisorFilters = () => {
  const { t } = useTranslation('osc-filters');
  const tripAdvisorRatingsInfo = GET_TRIP_ADVISOR_RATINGS_INFO(t);
  return (
    <fieldset id="trip-advisor-list">
      <h3 className="label label-inline-reverse px-4 py-3">{t('tripAdvisorRatingsLabel')}</h3>
      <div className="space-y-2 px-4 pb-4">
        {tripAdvisorRatingsInfo?.map(({ altOrLabel, value, onlyRatingImgSrc }, index) => (
          <FormInput
            className="!ml-0 rtl:!mr-0"
            key={value}
            labelClassName="label label-inline-reverse"
            id={value}
            label={
              <>
                {onlyRatingImgSrc ? (
                  <div className="-ml-3 flex items-center rtl:-mr-3">
                    <Image
                      alt={altOrLabel}
                      aria-hidden
                      src={onlyRatingImgSrc}
                      height={32}
                      width={100}
                    />
                    <span aria-hidden className="-ml-2 rtl:-mr-2">
                      {index === 0 ? t('onlyText') : t('andUpText')}
                    </span>
                  </div>
                ) : (
                  <span aria-hidden>{altOrLabel}</span>
                )}
                <span className="sr-only">{`${t('tripAdvisorRatingsLabel')} ${altOrLabel}`}</span>
              </>
            }
            name="tripAdvisorRating"
            type="radio"
            value={value}
          />
        ))}
      </div>
    </fieldset>
  );
};
